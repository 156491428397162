import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery/dist/jquery.min.js";
import Select from "react-select";
import { navigate } from "@reach/router"
import SearchInputBox from '../Predictive-search/search-inputbox';
import LocalSiteSearch from './LocalSiteSearch.js'
import SearchBox from "../SearchResult/SearchList/SearchBox";
import "./assets/styles/_index.scss"

import { inViewOptions, containerVariants, fadeInFromRight, fadeInFromBottom } from '../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const MotionCol = motion(Col)

const SearchOverlay = (props) => { 

    // const [department, setDepartment] = React.useState("residential")
    function closeSearchOverlay(e) {
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
    }


    useEffect(() => {

        console.log(props)
        let url = "/";

        $(".sales_overlay_btn").click(function () {
            var searcValue = $(".property_search_overlay .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
            if (searcValue !== "") {
                url = "in-" + searcValue + "/"
            } else {
                url = ""
            }

            var selectedDropdownSearch = $('#search_dropdown').val();
            navigate(selectedDropdownSearch + 'for-sale/' + url);
            props.closeBlock()
            // setTimeout(function(){ 
            //     $("body.modal-open").removeClass("modal-open")
            //     $(".search-overlay").removeClass("active-search")
            //     $(".search-modal").remove();   
            // }, 1000);
        })

        $(".rent_overlay_btn").click(function () {
            var searcValue = $(".property_search_overlay .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();

            if (searcValue !== "") {
                url = "in-" + searcValue + "/"
            } else {
                url = ""
            }

            var selectedDropdownSearch = $('#search_dropdown').val();
            navigate(selectedDropdownSearch + 'to-rent/' + url);
            props.closeBlock()
            // setTimeout(function(){ 
            //     $("body.modal-open").removeClass("modal-open")
            //     $(".search-overlay").removeClass("active-search")
            //     $(".search-modal").remove(); 
            // }, 1000);
        })
        
      
    }, []);

    const departments = [
        "residential",
        // if hav any other
    ]

    const highlight = (text) => {
        setTimeout(()=>{
            var inputText = document.getElementById("inputText");
            var innerHTML = inputText.innerHTML; 
            var regex = new RegExp(text, "g");  
            inputText.innerHTML = innerHTML.replace(regex,`<span class='highlight'>${text}</span>`) 
        },200)
      
    }
    return (
        <>
            <div className="search-overlay">
                <Container className="property-search-overlay_vertical_align">
                    <Row>
                        <MotionCol
                            xl={10}
                            lg={12}
                            variants={fadeInFromRight}
                        >
                            <Tabs defaultActiveKey="propertysearch" id="uncontrolled-tab-example" className="search-tab">
                                <Tab eventKey="sitesearch" title="Site Search"> 
                                    <LocalSiteSearch closeBlock={props.closeBlock}/>
                                    {/* <SiteSearch indexName={process.env.GATSBY_ALGOLIA_SITE_SEARCH_NAME} closeBlock={props.closeBlock} /> */}
                                </Tab>
                                <Tab eventKey="propertysearch" title="Property Search">
                                    <div className="input-group mb-3 property-search-overlay">
                                        {/* <select className="search-select d-none" id="search_dropdown" onChange={(e) => setDepartment(e.target.value)}> */}
                                        <select className="search-select d-none" id="search_dropdown">
                                            <option value="/property/">Residential</option>
                                            {/* <option value="new_developments">New Developments</option>  */}
                                        </select>
                                        <div className="input-group-prepend property-search-width property-search_overlay property_search_overlay">
                                            {/* <SearchInputBox
                                                departments={departments}
                                            /> */}
                                            <SearchBox areaVal={''}/>
                                        </div>
                                        <div className="property-search-btn_wrapper">
                                            <a href="javascript:void(0)" className="btn sales_overlay_btn btn-outline btn btn-link">Buy</a>
                                            <a href="javascript:void(0)" className="btn rent_overlay_btn btn-outline btn btn-link">Rent</a>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </MotionCol>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SearchOverlay
