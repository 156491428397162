import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery/dist/jquery.min.js'
import _ from 'lodash'

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, VIDEOS_PAGE_URL, AREA_GUIDES_PAGE_URL, LATEST_NEWS_PAGE_URL } from '../../Common/site/constants'


const PopularSearchDynamic = (props) => {
  //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)


  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText = ''
  var area = 'cannock'
  var typeText = 'Property'
  var searcharea = 'cannock'
  var searchareaText = 'Cannock'
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if (props.searchtype == 'sales') {
    searchaction = 'for Sale'
    URL = '/property/for-sale/'
  }
  if (props.searchtype == 'rentals') {
    searchaction = 'to Rent'
    URL = '/property/to-rent/'
  }
  if (props.searchtype == 'newhomesales') {
    searchaction = 'for Sale'
    URL = '/property/new-homes/for-sale/'
    typeText = 'New Property'
  }
  if (props.searchtype == 'newhomerentals') {
    searchaction = 'to Rent'
    URL = '/property/new-homes/to-rent/'
    typeText = 'New Property'
  }
  if (props.searchPtype) {
    ptypeURL = '/type-' + props.searchPtype
    typeText = capitalizeFirstLetter(props.searchPtype).replace("Buy-to-let-investments", "Buy to let investments")
  }
  if (props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield + ' Bedroom ' + typeText
    bedURL = '/' + props.searchBedroomfield + '-and-more-bedrooms'
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if (props.Searcharea && props.Searcharea.areas != 'undefined' && props.Searcharea.areas) {
    searcharea = props.Searcharea.areas
    searcharea = searcharea.toLowerCase().replace(/\s/g , '-');
    searchareaText = props.Searcharea.areas.replace(/%20/g, " ")
  }


  useEffect(() => {
    $(".footer_scroll_top").click(function () { 
      $('html, body').animate({
        scrollTop: $("#property_list_results").offset().top - 200
      }, 50);
    });
  })

  const getAreaGuideURL = (area)=>{
    let areas = ['telford', 'shrewsbury','wolverhampton', 'stafford', 'wellington', 'oakengates', 'cannock']
    var url =  AREA_GUIDES_PAGE_URL.alias;
    if(_.includes(areas, _.toLower(area))){
      url = url + "/"+ area
    }
    return url
   
  }
  const getOfficeURL = (office)=>{
    let offices = ['telford', 'shrewsbury','wolverhampton', 'stafford', 'wellington', 'oakengates', 'cannock']
    var url =  CONTACT_PAGE_URL.alias;
    if(_.includes(offices, _.toLower(office))){
      url = url + "/"+ area
    }
    return url
   
  }
 
  return (
    <div className="popular-search">
      <Row>
        {props.searchBedroomfield ?
          <Col md={6} lg={3}>
            <ul>
              <li><Link className="footer_scroll_top" to={`${URL}in-telford${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Telford</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-shrewsbury${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Shrewsbury</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-wolverhampton${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Wolverhampton</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-stafford${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Stafford</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-wellington${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Wellington</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-oakengates${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Oakengates</Link></li>
              {props.searchPtype &&
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}`}>Property {searchaction} in {searchareaText}</Link></li>
              }
            </ul>
          </Col>
          : <Col md={6} lg={3}>
            <ul>
              <li><Link className="footer_scroll_top" to={`${URL}in-telford${ptypeURL}`}>{typeText} {searchaction} in Telford</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-shrewsbury${ptypeURL}`}>{typeText} {searchaction} in Shrewsbury</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-wolverhampton${ptypeURL}`}>{typeText} {searchaction} in Wolverhampton</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-stafford${ptypeURL}`}>{typeText} {searchaction} in Stafford</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-wellington${ptypeURL}`}>{typeText} {searchaction} in Wellington</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-oakengates${ptypeURL}`}>{typeText} {searchaction} in Oakengates</Link></li>

            </ul>
          </Col>
        }


        {searcharea ?
          <Col md={6} lg={3}>

            {(!props.searchBedroomfield && !props.SearchPrice && (_.isEmpty(props.searchPtype)) || !props.searchBedroomfield && props.SearchPrice && (_.isEmpty(props.searchPtype))) ?
              <ul>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-apartment`}>Apartments {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-flats`}>Flats {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-detached-houses`}>Detached Houses {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-terraced-houses`}>Terraced Houses {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-bungalows`}>Bungalows {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-semi-detachedhouses`}>Semi Detached Houses {searchaction} in {searchareaText}</Link></li>
              </ul>
              : props.searchBedroomfield && !props.SearchPrice && (!_.isEmpty(props.searchPtype)) ?

                <ul>
                  <li><Link className="footer_scroll_top" to={`${URL}in-telford${ptypeURL}`}>{typeText} {searchaction} in Telford</Link></li>
                  <li><Link className="footer_scroll_top" to={`${URL}in-shrewsbury${ptypeURL}`}>{typeText} {searchaction} in Shrewsbury</Link></li>
                  <li><Link className="footer_scroll_top" to={`${URL}in-wolverhampton${ptypeURL}`}>{typeText} {searchaction} in Wolverhampton</Link></li>
                  <li><Link className="footer_scroll_top" to={`${URL}in-stafford${ptypeURL}`}>{typeText} {searchaction} in Stafford</Link></li>
                  <li><Link className="footer_scroll_top" to={`${URL}in-wellington${ptypeURL}`}>{typeText} {searchaction} in Wellington</Link></li>
                  <li><Link className="footer_scroll_top" to={`${URL}in-oakengates${ptypeURL}`}>{typeText} {searchaction} in Oakengates</Link></li>
                </ul>  :
                  <ul>
                    <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}${ptypeURL}/1-and-more-bedrooms`}>1 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                    <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}${ptypeURL}/2-and-more-bedrooms`}>2 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                    <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}${ptypeURL}/3-and-more-bedrooms`}>3 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                    <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}${ptypeURL}/4-and-more-bedrooms`}>4 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                    <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}${ptypeURL}/5-and-more-bedrooms`}>5 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                    <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}${ptypeURL}/6-and-more-bedrooms`}>6 Bedroom {props.searchPtype ? typeText : 'Property'} {searchaction} in {searchareaText}</Link></li>
                  </ul> 
            }
          </Col> :
          <Col md={6} lg={3}>
            <ul>
              <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-apartment`}>Apartments {searchaction} in {searchareaText}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-flats`}>Flats {searchaction} in {searchareaText}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-detached-houses`}>Detached Houses {searchaction} in {searchareaText}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-terraced-houses`}>Terraced Houses {searchaction} in {searchareaText}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-bungalows`}>Bungalows {searchaction} in {searchareaText}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-semi-detachedhouses`}>Semi Detached Houses {searchaction} in {searchareaText}</Link></li>
            </ul>
          </Col>
        }


        {searcharea ?
          <Col md={6} lg={3}>
            {props.searchBedroomfield || props.searchPtype ?
              <ul>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-apartment`}>Apartments {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-flats`}>Flats {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-detached-houses`}>Detached Houses {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-terraced-houses`}>Terraced Houses {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-bungalows`}>Bungalows {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-semi-detachedhouses`}>Semi Detached Houses {searchaction} in {searchareaText}</Link></li>
              </ul> :
              <ul>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/1-and-more-bedrooms`}>1 Bedroom Property {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/2-and-more-bedrooms`}>2 Bedroom Property {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/3-and-more-bedrooms`}>3 Bedroom Property {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/4-and-more-bedrooms`}>4 Bedroom Property {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/5-and-more-bedrooms`}>5 Bedroom Property {searchaction} in {searchareaText}</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/6-and-more-bedrooms`}>6 Bedroom Property {searchaction} in {searchareaText}</Link></li>
              </ul>
            }
          </Col> :
          <Col md={6} lg={3}>
            <ul>
              <li><Link className="footer_scroll_top" to={`${URL}in-london/type-bungalow`}>Bungalows {searchaction} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-london/type-apartment`}>Apartments {searchaction} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}in-london/type-house`}>Houses {searchaction} in {area}</Link></li>
            </ul>
          </Col>
        }


        <Col md={6} lg={3}>
          <ul>
            <li><Link className="" to={`${getAreaGuideURL(searchareaText)}`}>{capitalizeFirstLetter(searchareaText)} Area Guides</Link></li>
            <li><Link className="" to={`${getOfficeURL(searchareaText)}`}>Estate Agents in {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>Property Valuation {capitalizeFirstLetter(searchareaText)}</Link></li>
            <li><Link className="" to={`${VIDEOS_PAGE_URL.alias}`}>Property Videos</Link></li>
            <li><Link className="" to={`${ABOUT_PAGE_URL.alias}`}>About DB Roberts</Link></li>
            <li><Link className="" to={`${LATEST_NEWS_PAGE_URL.alias}`}>Latest Property News</Link></li>
          </ul>
        </Col>
      </Row>


    </div>)

}

export default PopularSearchDynamic;
