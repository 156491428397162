import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import CookieConsent from "react-cookie-consent";
import './Cookies.scss';
const Cookies = (props) => {

  return (
    <React.Fragment>
      <CookieConsent
        disableStyles={true}
        buttonClasses="btn btn-primary"
        containerClasses="cookies-section d-none d-lg-block"
        contentClasses="cokkies-txt"
        buttonClasses="btn btn-link gotit"
        buttonText="Accept Cookies"

      >
        We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookie-policy">Cookie Policy</Link>.
      </CookieConsent>
    </React.Fragment>

  )
}



export default Cookies