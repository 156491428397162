import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash";
import axios from "axios";


export const GetURL = (id) => {
	const data = useStaticQuery(graphql`
    query {
	glstrapi {
		 allMenus {
		   URL
		   Label
		   Template
		   Link_Type
		   Main_Parent {
		     Label
		     URL
		     Alias
		   }
		   Alias
		   Sub_Parent {
		     Alias
		     Label
		     URL
		   }
		   Article {
		     _id
		     Pagename
		   }
		   id 
		 }
	}
    }
  `);

	let PageURL = data.glstrapi.allMenus.filter(item => item.id === id).pop();


	let URL = PageURL.URL
	let link_type = PageURL.Link_Type

	if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent == null)) {
		URL = PageURL.Main_Parent.URL + '/' + URL
	}

	else if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent != null)) {
		URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' + URL
	}

	return [URL, link_type];

}


export const NewsDate = (date) => {

	const d = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<span>{`${da} ${mo} ${ye}`}</span>
	)
}

export const limitedContent = (content, limit) => {
	if (!_.isEmpty(content)) {
		if (content.length > limit) {
			return content.substring(0, limit) + '...';
		}
	}
	return content;
}
export const lazyLoader = (element, listener) => {
	var events = ['mousemove','touchmove','keypress'];
	for (var i=0, iLen=events.length; i<iLen; i++) {
	  element.addEventListener(events[i], listener, false);
	}
} 

export const appendScript = (url,id, callback) => {  
  var e = document.createElement("script");
  e.src = url;
  e.id = id;
  e.type="text/javascript";
  e.addEventListener('load', callback);
  document.getElementsByTagName("body")[0].appendChild(e); 
} 



export const feefo = (cb) => {

	axios.get('https://api.feefo.com/api/10/reviews/summary/all?merchant_identifier=dbroberts')
		.then(function (response) {
			// handle success
		//	console.log(response);
			cb(response.data);
		})
		.catch(function (error) {
			// handle error
			cb(null);
			console.log(error);
		}) 
	
}

export const allFeefo = (cb) => { 
	axios.get('https://api.feefo.com/api/10/reviews/all?merchant_identifier=dbroberts')
		.then(function (response) {
			// handle success
		//	console.log(response);
			cb(response.data);
		})
		.catch(function (error) {
			// handle error
			cb(null);
			console.log(error);
		}) 
	
}

