import { Link } from "@StarberryUtils";
import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
const OurOffice = (props) => { 
    return (
        <React.Fragment>
            <div className="our-office">
                <Row>
                    {props.data && props.data.map((item, index) => (
                        <Col xl="3" lg="4" md="4" className="d-flex" key={index}>
                            <div className="footer-link_box">
                                <h3><Link to={`/contact/${item.URL}`}>{item.Name}</Link></h3>
                                {item.Address &&
                                    HTMLReactParser(item.Address)
                                }
                                <div className="phone_number">
                                    <a href={`tel:+44${item.Mobile}`}> {item.Mobile} </a>
                                </div>
                            </div>
                        </Col>
                    ))}

                </Row>
            </div>
        </React.Fragment>
    )
}

export default OurOffice;