import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
 

import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, VIDEOS_PAGE_URL, AREA_GUIDES_PAGE_URL, LATEST_NEWS_PAGE_URL } from '../../Common/site/constants'


const PopularSearchAreaDetails = (props) => { 
 
  var typeText = ''
  var URL = '/property/for-sale/'
  var RentURL = '/property/to-rent/'
  var ContactURL = '/contact/'
  var area = 'Cannock'
  var areaslug = ''


  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);  
  }
  if (props.title) {
    area = props.title
  }
  areaslug = area.toLowerCase().replace(/\s/g , '-');
  return (
    <Row>
      <Col md={6} lg={3}>
        <ul>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}`}>Property for Sale in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment`}>Apartments for Sale in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-flats`}>Flats  for Sale in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-detached-houses`}>Detached Houses for Sale in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-terraced-houses`}>Terraced Houses for Sale in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalows`}>Bungalows for Sale in {area}</Link></li> 
        </ul>
      </Col>
      <Col md={6} lg={3}>
        <ul>
          <li><Link className="footer_scroll_top" to={`${RentURL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}`}>Property to Rent in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${RentURL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment`}>Apartments to Rent in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${RentURL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-flats`}>Flats to Rent in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${RentURL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-detached-houses`}>Detached Houses to Rent in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${RentURL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-terraced-houses`}>Terraced Houses to Rent in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${RentURL}in-${areaslug?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalows`}>Bungalows to Rent in {area}</Link></li> 
        </ul>
      </Col>  
      <Col md={6} lg={3}>
        <ul>
          <li><Link className="footer_scroll_top" to={`${ContactURL}telford`}> Estate Agents in Telford </Link></li>
          <li><Link className="footer_scroll_top" to={`${ContactURL}shrewsbury`}>Estate Agents in Shrewsbury</Link></li>
          <li><Link className="footer_scroll_top" to={`${ContactURL}telford`}>Estate Agents in Stafford</Link></li>
          <li><Link className="footer_scroll_top" to={`${ContactURL}wolverhampton`}>Estate Agents in Wolverhampton</Link></li>
          <li><Link className="footer_scroll_top" to={`${ContactURL}wellington`}>Estate Agents in Wellington</Link></li>
          <li><Link className="footer_scroll_top" to={`${ContactURL}oakengates`}>Estate Agents in Oakengates</Link></li> 
        </ul>
      </Col> 
      <Col md={6} lg={3}> 
        <ul> 
          <li><Link className="" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>Property Valuation West Midlands</Link></li>
          <li><Link className="" to={`/property-services/auctions`}>Property Auction Services</Link></li>
          <li><Link className="" to={`${ABOUT_PAGE_URL.alias}`}>About DB Roberts</Link></li>
          <li><Link className="" to={`${ContactURL}our-team`}>Meet the Team</Link></li> 
          <li><Link className="" to={`/property-services`}>Testimonials</Link></li>
          <li><Link className="" to={`${AREA_GUIDES_PAGE_URL.alias}/telford`}>Telford Area Guides</Link></li>
        </ul>
      </Col>
    </Row>
  )

}

export default PopularSearchAreaDetails;
