import React, { useState, useEffect, PropTypes } from "react"
import Autosuggest from 'react-autosuggest';
import _ from "lodash"
import axios from 'axios';




// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.displayName;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.displayName}
  </div>
);


class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      areas: [],
      aliasVal: "",
      isChages: false
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      aliasVal: newValue,
      isChages: true
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  suggestionSelected = (e, { suggestion }) => {

    if (suggestion) {
      this.setState({
        value: suggestion.displayName,
        aliasVal: suggestion.location,
      });
    }
  }
  getitems = async url => {
    try {
      const { data } = await axios.get(url, {})
      this.setState({ areas: data })
    } catch (error) {
      console.error(error)
    }
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    var exactMatch = [];
    var randomMatch = [];
    var suggestList = [];
    exactMatch = inputLength === 0 ? [] : this.state.areas.filter(area =>
      area.displayName.toLowerCase().slice(0, inputLength) === inputValue
    );

    randomMatch = inputLength === 0 ? [] : this.state.areas.filter(area =>
      area.displayName.toLowerCase().includes(inputValue.toLowerCase())
    );

    exactMatch.forEach((val) => {
      suggestList.push(val)
    })
    randomMatch.forEach((val) => {
      suggestList.push(val)
    })
    return _.uniqBy(suggestList, 'displayName')
  };


  componentDidMount() {
    let url = process.env.GATSBY_STRAPI_SRC + "/json/areas.json" // process.env.GATSBY_STRAPI_SRC + "/areas"; // TODO: base URL supposed to be from .env

    this.getitems(url)
    //this.setState({ value: this.props.areaVal }) 
  }
  componentDidUpdate(prevProps) {
    if (prevProps.areaVal != this.props.areaVal) {
      if (!_.isEmpty(this.props.areaVal)) {
        this.setState({ value: _.capitalize(this.props.areaVal.replace(/-/g, " ").replace(/  +/g, ' ')) })
      }
    } else if(_.isEmpty(this.state.value) && !this.state.isChages && !_.isEmpty(this.props.areaVal)){
      this.setState({ value: _.capitalize(this.props.areaVal.replace(/-/g, " ").replace(/  +/g, ' ')) })
    } 
  }

  render() {
    const { value, suggestions, isChages, aliasVal } = this.state;

    // Autosuggest will pass through all these props to the input.
    // var setVal = value;
    // if (!_.isEmpty(this.props.areaVal) && !isChages) {
    //   setVal = this.props.areaVal;
    // }


    const inputProps = {
      placeholder: 'Street, area or postcode',
      value: value,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={this.suggestionSelected}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}

        />
        <input type="hidden" className="searchVal" value={aliasVal} />
      </>
    );
  }
}

export default SearchBox;
