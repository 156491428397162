import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Row, Col } from 'react-bootstrap';
import _ from "lodash"
import { BOOK_A_VALUATION_PAGE_URL, CONTACT_PAGE_URL, ABOUT_PAGE_URL, VIDEOS_PAGE_URL, AREA_GUIDES_PAGE_URL, LATEST_NEWS_PAGE_URL } from '../../Common/site/constants'


const PopularSearchDetails = (props) => {

  var propertydetails = props.propertydetails
  var typeText = ''
  var URL = ''
  var area = 'Cannock'
  var areaslug = ''
  var ptypeURL = '/type-' + propertydetails.building


  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getAreaGuideURL = (area) => {
    let areas = ['telford', 'shrewsbury', 'wolverhampton', 'stafford', 'wellington', 'oakengates', 'cannock']
    var url = AREA_GUIDES_PAGE_URL.alias;
    if (_.includes(areas, _.toLower(area))) {
      url = url + "/" + area
    }
    return url

  }
  const getOfficeURL = (office) => {
    let offices = ['telford', 'shrewsbury', 'wolverhampton', 'stafford', 'wellington', 'oakengates', 'cannock']
    var url = CONTACT_PAGE_URL.alias;
    if (_.includes(offices, _.toLower(office))) {
      url = url + "/" + area
    }
    return url

  }

  if (propertydetails.address.town) {
    area = propertydetails.address.town
  }
  if (propertydetails.search_type == 'sales') {
    typeText = 'for Sale'
    URL = '/property/for-sale/'
  }
  if (propertydetails.search_type == 'lettings') {
    typeText = 'to Rent'
    URL = '/property/to-rent/'
  }
  areaslug = area.toLowerCase().replace(/\s/g , '-');
  return (
    <Row>
      <Col md={6} lg={3}>
        <ul>
          <li><Link className="footer_scroll_top" to={`${URL}in-telford`}>Property {typeText} in Telford</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-shrewsbury`}>Property {typeText}in Shrewsbury</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-wolverhampton`}>Property {typeText} in Wolverhampton</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-stafford`}>Property {typeText} in Stafford</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-wellington`}>Property {typeText} in Wellington</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-oakengates`}>Property {typeText} in Oakengates</Link></li>
        </ul>
      </Col>
      <Col md={6} lg={3}>
        <ul>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/type-apartment`}>Apartments {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/type-flats`}>Flats {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/type-detached-houses`}>Detached Houses {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/type-terraced-houses`}>Terraced Houses {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/type-bungalows`}>Bungalows {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/type-semi-detachedhouses`}>Semi Detached Houses {area} in {typeText}</Link></li>
        </ul>
      </Col>
      <Col md={6} lg={3}>
        <ul>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/1-and-more-bedrooms`}>1 Bedroom property {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/2-and-more-bedrooms`}>2 Bedroom Property {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/3-and-more-bedrooms`}>3 Bedroom Property {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/4-and-more-bedrooms`}>4 Bedroom Property {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/5-and-more-bedrooms`}>5 Bedroom Property {typeText} in {area}</Link></li>
          <li><Link className="footer_scroll_top" to={`${URL}in-${areaslug}/6-and-more-bedrooms`}>6 Bedroom Property {typeText} in {area}</Link></li>
        </ul>
      </Col>
      <Col md={6} lg={3}>
        <ul> 
          <li><Link className="" to={`${getAreaGuideURL(area)}`}>{capitalizeFirstLetter(area)} Area Guides</Link></li>
          <li><Link className="" to={`${getOfficeURL(area)}`}>Estate Agents in {capitalizeFirstLetter(area)}</Link></li>
          <li><Link className="" to={`${BOOK_A_VALUATION_PAGE_URL.alias}`}>Property Valuation {capitalizeFirstLetter(area)}</Link></li>
          <li><Link className="" to={`${VIDEOS_PAGE_URL.alias}`}>Property Videos</Link></li>
          <li><Link className="" to={`${ABOUT_PAGE_URL.alias}`}>About DB Roberts</Link></li>
          <li><Link className="" to={`${LATEST_NEWS_PAGE_URL.alias}`}>Latest Property News</Link></li>
        </ul>
      </Col>
    </Row>
  )

}

export default PopularSearchDetails;
