import React, { Component } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  Hits,
  Highlight,
  connectSearchBox,
  RefinementList
} from 'react-instantsearch-dom';
import Autocomplete from './search-Autocomplete';
import './assets/styles/_index.scss';

const VirtalSearchBox = connectSearchBox(() => null);

const searchClient = algoliasearch(
	`${process.env.GATSBY_ALGOLIA_APP_ID}`,
	`${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
); 

class SearchInputBox extends Component {
  state = {
    query: '',
  };

  onSuggestionSelected = (_, { suggestion }) => { 
    this.setState({
      query: suggestion.display_address,
    });
  };

  onSuggestionCleared = () => {
    this.setState({
      query: '',
    });
  };

  render() {
    const { query } = this.state;
    const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
    
    return (
      <div className="form-group autosuggest_container">
        <InstantSearch indexName={index_name} searchClient={searchClient}>
          <div className="d-none">
              {/* <RefinementList
                  //attribute="publish"
                //  defaultRefinement={["true"]}
              /> */}
              <RefinementList
                attribute="department"
                defaultRefinement={this.props.departments}
              />
          </div>
          <Configure hitsPerPage={5} distinct={true} />
          <Autocomplete
            onSuggestionSelected={this.onSuggestionSelected}
            onSuggestionCleared={this.onSuggestionCleared}
            handleChange={this.props.handleChange}
          />
        </InstantSearch>

        <InstantSearch indexName={index_name} searchClient={searchClient}>
          <VirtalSearchBox defaultRefinement={query} />
        </InstantSearch>
      </div>
    );
  }
}

function Hit(props) {
  return (
    <div>
      <Highlight attribute="display_address" hit={props.hit} />
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default SearchInputBox;
