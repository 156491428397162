import React from "react";
import _ from "lodash";
import { useStaticQuery, graphql } from "gatsby"
import HTMLReactParser from "html-react-parser";

const PopularSearch = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi { 
            popularSearch { 
                Popular_Search_Home
                Popular_Search_Sellers
                Popular_Search_Tenants
                Popular_Search_About
                Popular_Search_Contact
                Popular_Search_Area_Guides 
            }
        }
        }
    `);
    const search = data.glstrapi.popularSearch;
    return (
        <React.Fragment>
            <div className="popular-search">
                {search[props.popular_search] &&
                    HTMLReactParser(search[props.popular_search].replace(/<br\s*\/?>/gi, ''))
                }
            </div> 
        </React.Fragment>
    )
}

export default PopularSearch;